import { post, get, ResponseType, URL } from "@/utils/axios";

//获取书籍分类
export const getAllBookCategories = () => {
    return get(URL.EDITOR_BOOK_UPDATE_MONITORING_BOOK_CATEGORIES)
}

//获取书籍更新查询列表
export const getBookUpdateMonitoringList = (params: {
    editorId: number | string,
    bookId: number | string,
    bookUpdateType: string,
    bookCategoryId: number | string,
    page: number,
    size: number
}) => {
    return get(URL.EDITOR_BOOK_UPDATE_MONITORING_LIST, { params })
}

//删除数据
export const deleteBookUpdateMonitoring = (data: {
    id: number
}) => {
    return post(URL.EDITOR_BOOK_UPDATE_MONITORING_DELETE, {data})
}

//清空数据
export const truncateBookUpdateMonitoring = () => {
    return post(URL.EDITOR_BOOK_UPDATE_MONITORING_TRUNCATE)
}

//导入
export const importBookUpdateMonitoring = (data: {
    bookIds: string
}) => {
    return post(URL.EDITOR_BOOK_UPDATE_MONITORING_IMPORT, {data})
}

//查询导出信息
export const getBookUpdateMonitoringExportInfo = () => {
    return get(URL.EDITOR_BOOK_UPDATE_MONITORING_EXPORT_INFO)
}

//修改导出信息
export const modifyBookUpdateMonitoringExportInfo = (data: {
    receivers: string,
    receiveTime: string,
    status: string
}) => {
    return post(URL.EDITOR_BOOK_UPDATE_MONITORING_EXPORT_INFO, {data})
}